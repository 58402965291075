<template>
  <div>
    <transition name="fade">
      <LoadingPage v-if="shouldShowLoading" />
    </transition>

    <main v-show="!shouldShowLoading">
      <BalanceCard
        :formattedBalance="formattedBalance"
        v-if="hasInitialData"
      />
      
      <ServiceItems v-if="hasInitialData" />
      
      <template v-if="hasInitialData">
        <AsyncCowAnimation
          :profit="profit"
          :remainingTime="remainingTime"
          :claim="handleClaim"
        />

        <AsyncTutorial
          v-if="showTutorial"
          @close="closeTutorial"
        />
      </template>
    </main>

    <router-view></router-view>
  </div>
</template>

<script>
import { inject, defineAsyncComponent } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import BalanceCard from "@/components/BalanceCard.vue";
import ServiceItems from "@/components/ServiceItems.vue";
import SimpleSpinner from "@/components/SimpleSpinner.vue";
import LoadingPage from "@/components/LoadingPage.vue";

const AsyncCowAnimation = defineAsyncComponent({
  loader: () => import("@/components/CowAnimation.vue"),
  loadingComponent: SimpleSpinner,
  delay: 200,
  timeout: 5000,
});

const AsyncTutorial = defineAsyncComponent({
  loader: () => import("@/components/Tutorial.vue"),
  delay: 200,
});

export default {
  name: "Home",
  
  components: {
    BalanceCard,
    ServiceItems,
    SimpleSpinner,
    AsyncCowAnimation,
    AsyncTutorial,
    LoadingPage
  },

  setup() {
    const showGlobalAlert = inject("showGlobalAlert");
    return { showGlobalAlert };
  },

  computed: {
    ...mapState([
      "balance",
      "profit",
      "remainingTime",
      "isPremium",
      "isClaimDisabled",
      "claimed_at",
      "userName",
      "firstName",
      "lastName",
      "isLoading",
      "showTutorial",
      "showAnimations",
      "hasInitialData",
      "apiError",
      "isInitialized",
      "isFirstVisit"
    ]),
    ...mapGetters(["formattedBalance", "needsReinitialization"]),

    shouldShowLoading() {
      return (this.isLoading && this.isFirstVisit) || (!this.hasInitialData && this.isFirstVisit);
    }
  },

  async created() {
    await this.initializeHome();
  },

  unmounted() {
    this.clearTimer();
  },

  methods: {
    ...mapActions([
      "initializeData",
      "restoreCachedData",
      "claim",
      "updateClaimStatus",
      "forceReinitialization",
      "startTimer",
      "clearTimer",
      "setFirstVisit"
    ]),

    async initializeHome() {
      try {
        if (this.needsReinitialization || !this.hasInitialData) {
          const result = await this.initializeData();
          if (result?.status === "success") {
            this.startTimer();
            this.setFirstVisit(false);
          }
        } else {
          this.startTimer();
        }
      } catch (error) {
        this.showGlobalAlert(error.message, "error");
        console.error("Initialization failed:", error);
      }
    },

    closeTutorial() {
      this.$store.commit("SET_SHOW_TUTORIAL", false);
      localStorage.setItem("tutorial", "seen");
    },

    async handleClaim() {
      try {
        const result = await this.claim();
        if (result?.status === "success") {
          this.showGlobalAlert("Claim was successful!", "success");
        }
      } catch (error) {
        this.showGlobalAlert(error.message, "error");
        console.error("Claim failed:", error);
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>