import { createRouter, createWebHistory } from "vue-router";

import Home from "./pages/Home.vue";
import Tasks from "./pages/Tasks.vue";
import Reward from "./pages/Reward.vue";
import Friends from "./pages/Friends.vue";
import Leader from "./pages/Leader.vue";
import Shop from "./pages/Shop.vue";

const requireAuth = (to, from, next) => {
  if (!window.Telegram?.WebApp?.initData) {
    window.location.replace('https://cowterest.online/paper');
  } else {
    next();
  }
};

const routes = [
  { 
    path: "/", 
    name: "home", 
    component: Home,
    beforeEnter: requireAuth,
  },
  { 
    path: "/tasks", 
    name: "tasks", 
    component: Tasks,
  },
  { 
    path: "/friends", 
    name: "friends", 
    component: Friends,
  },
  { 
    path: "/reward", 
    name: "reward", 
    component: Reward,
  },
  { 
    path: "/leader", 
    name: "leader", 
    component: Leader,
  },
  { 
    path: "/shop", 
    name: "shop", 
    component: Shop,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
