```vue
<template>
  <div class="grid">
    <!-- Airdrop -->
    <div class="item disabled" style="margin-left: 10px;">
      <div class="inner">
        <div class="icon">
          <svg
            width="25"
            height="25"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <path d="M312.942,424.774L276,461.28V276c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v183.303l-34.942-34.529 c-7.857-7.764-20.52-7.688-28.284,0.168c-7.764,7.857-7.688,20.52,0.168,28.284l41.589,41.098C225.878,505.724,240.955,512,257,512s31.122-6.276,42.469-17.676l41.589-41.098c7.856-7.764,7.932-20.427,0.168-28.284 C333.461,417.085,320.799,417.009,312.942,424.774z" />
            <path d="M492,276c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40h-96c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h96 c44.112,0,80-35.888,80-80C512,284.954,503.046,276,492,276z" />
            <path d="M311.172,253.815c3.35,2.157,7.101,3.188,10.809,3.187c6.573,0,13.011-3.237,16.834-9.174 C350.712,229.354,357,208.277,357,186.878c0-59.551-45.309-108-101-108c-55.691,0-101,48.449-101,108 c0,22.906,5.798,43.438,17.233,61.024c6.021,9.26,18.41,11.886,27.669,5.865c9.26-6.021,11.886-18.409,5.865-27.669 C198.623,215.109,195,201.915,195,186.878c0-37.495,27.364-68,61-68s61,30.505,61,68c0,13.702-4.085,27.29-11.815,39.293 C299.205,235.459,301.885,247.835,311.172,253.815z" />
            <path d="M432,0H80C35.888,0,0,35.888,0,80v216c0,44.112,35.888,80,80,80h96c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80 c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h352c22.056,0,40,17.944,40,40v116c0,11.046,8.954,20,20,20 c11.046,0,20-8.954,20-20V80C512,35.888,476.112,0,432,0z" />
          </svg>
        </div>
        <span class="label">Airdrop</span>
      </div>
    </div>

    <!-- Wallet -->
    <div class="item wallet">
      <div class="inner">
        <TonConnectButton />
      </div>
    </div>

    <!-- Farm -->
    <router-link :to="{ name: 'reward' }" class="item" style="margin-right: 10px;">
      <div class="inner">
        <div class="icon">
          <img src="img/menu/farm2.svg" alt="Farm" />
        </div>
        <span class="label">Farm</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { TonConnectButton } from "@townsquarelabs/ui-vue";

export default {
  name: "ServiceItems",
  components: {
    TonConnectButton,
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1.5rem 0;
}

.item {
  background: rgba(187, 66, 151, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  text-decoration: none;
}

.inner {
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.icon {
  width: 48px;
  height: 48px;
  color: white;
  filter: drop-shadow(0 0 8px #ff69b4);
}

.icon img,
.icon svg {
  width: 100%;
  height: 100%;
}

.label {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 2px 2px 4px #ff69b4;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.wallet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item:not(.disabled):active {
  transform: scale(0.95);
  background: rgba(187, 66, 151, 0.9);
}

@media (max-width: 380px) {
  .inner { padding: 1rem }
  .icon { width: 40px; height: 40px }
  .label { font-size: 0.875rem }
}
</style>
```