import { createStore } from "vuex";
import shop from "./modules/shop";
import leaderboard from "./modules/leaderboard";
import friends from "./modules/friends";

const store = createStore({
  modules: {
    shop,
    leaderboard,
    friends,
  },

  state: {
    token: localStorage.getItem("token") || null,
    balance: 0,
    profit: 0,
    remainingTime: "",
    isPremium: false,
    isClaimDisabled: false,
    claimed_at: null,
    userName: null,
    firstName: null,
    lastName: null,
    isLoading: true,
    showTutorial: false,
    showAnimations: false,
    hasInitialData: false,
    apiError: null,
    isInitialized: false,
    lastInitTime: null,
    timer: null,
    isFirstVisit: true
  },

  getters: {
    formattedBalance: (state) => {
      return state.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formattedProfit: (state) => {
      return state.profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getAuthHeaders: (state) => {
      return {
        initData: window.Telegram?.WebApp?.initData,
        StartApp: window.Telegram?.WebApp?.initDataUnsafe?.start_param ?? null,
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token}`,
      };
    },

    needsReinitialization: (state) => {
      if (!state.isInitialized) return true;
      if (!state.lastInitTime) return true;

      const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
      return Date.now() - state.lastInitTime > REFRESH_INTERVAL;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },

    SET_USER_DATA(state, data) {
      state.userName = data.username;
      state.firstName = data.first_name;
      state.lastName = data.last_name;
      state.isPremium = data.is_premium;
      state.balance = data.wallet.balance;
      state.profit = data.wallet.profit;
      state.claimed_at = data.wallet.claimed_at;
      state.hasInitialData = true;
    },

    UPDATE_BALANCE(state, { balance, claimed_at, profit }) {
      state.balance = balance;
      state.claimed_at = claimed_at;
      if (profit !== undefined) {
        state.profit = profit;
      }
    },

    UPDATE_PROFIT(state, profit) {
      state.profit = profit;
    },

    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },

    SET_CLAIM_DISABLED(state, isDisabled) {
      state.isClaimDisabled = isDisabled;
    },

    SET_REMAINING_TIME(state, time) {
      state.remainingTime = time;
    },

    SET_SHOW_TUTORIAL(state, show) {
      state.showTutorial = show;
    },

    SET_SHOW_ANIMATIONS(state, show) {
      state.showAnimations = show;
    },

    SET_API_ERROR(state, error) {
      state.apiError = error;
    },

    SET_INITIALIZED(state, value) {
      state.isInitialized = value;
      state.lastInitTime = value ? Date.now() : null;
    },

    SET_FIRST_VISIT(state, value) {
      state.isFirstVisit = value;
    },

    RESET_INITIALIZATION(state) {
      state.isInitialized = false;
      state.lastInitTime = null;
    },

    RESTORE_CACHED_DATA(state, data) {
      Object.assign(state, data);
      state.hasInitialData = true;
    },

    SET_TIMER(state, timer) {
      if (state.timer) {
        clearInterval(state.timer);
      }
      state.timer = timer;
    },
  },

  actions: {
    async fetchWithTimeout({ commit }, { url, options = {}, timeout = 5000 }) {
      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), timeout);

      try {
        const response = await fetch(url, {
          ...options,
          signal: controller.signal,
        });
        return response;
      } finally {
        clearTimeout(id);
      }
    },

    async initializeData({ commit, dispatch, state, getters }) {
      // Only show loading on first visit or page reload
      if (state.isFirstVisit) {
        commit('SET_LOADING', true);
      }

      try {
        const response = await dispatch("fetchWithTimeout", {
          url: "https://cowterest.online/api/init",
          options: {
            method: "POST",
            headers: getters.getAuthHeaders,
          },
        });

        const data = await response.json();

        if (data.status === "success") {
          commit("SET_TOKEN", data.data.token);
          commit("SET_USER_DATA", data.data);
          dispatch("cacheData");
          commit("SET_SHOW_ANIMATIONS", true);
          dispatch("checkTutorial");
          commit("SET_INITIALIZED", true);
          commit("SET_FIRST_VISIT", false); // Mark first visit as complete
          return { status: "success" };
        } else {
          throw new Error(data.message || "Initialization failed");
        }
      } catch (error) {
        commit("SET_API_ERROR", error.message);
        if (!state.hasInitialData) {
          setTimeout(() => location.reload(), 5000);
        }
        return Promise.reject(error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async updateWalletData({ commit, dispatch }, { balance, profit, claimed_at }) {
      commit("UPDATE_BALANCE", {
        balance,
        claimed_at,
        profit,
      });
      await dispatch("cacheData");
    },

    forceReinitialization({ commit, dispatch }) {
      commit("SET_FIRST_VISIT", true); // Reset first visit for reinitialization
      commit("RESET_INITIALIZATION");
      return dispatch("initializeData");
    },

    cacheData({ state }) {
      const cacheData = {
        balance: state.balance,
        profit: state.profit,
        claimed_at: state.claimed_at,
        isPremium: state.isPremium,
        userName: state.userName,
        firstName: state.firstName,
        lastName: state.lastName,
      };
      sessionStorage.setItem("homeData", JSON.stringify(cacheData));
    },

    restoreCachedData({ commit, state }) {
      const cached = sessionStorage.getItem("homeData");

      if (cached) {
        try {
          const data = JSON.parse(cached);
          commit("RESTORE_CACHED_DATA", data);
          if (!state.isInitialized) {
            commit("SET_INITIALIZED", true);
          }
          commit("SET_LOADING", false);
        } catch (e) {}
      } else {
        state.hasInitialData = false;
      }
    },

    checkTutorial({ commit }) {
      const tutorialSeen = localStorage.getItem("tutorial");
      commit("SET_SHOW_TUTORIAL", !tutorialSeen);
    },

    async claim({ commit, dispatch, state, getters }) {
      if (state.isClaimDisabled || !state.token) return;

      commit("SET_CLAIM_DISABLED", true);

      try {
        const response = await dispatch("fetchWithTimeout", {
          url: "https://cowterest.online/api/claim",
          options: {
            method: "POST",
            headers: getters.getAuthHeaders,
          },
        });

        const data = await response.json();

        if (data.status === "success") {
          await dispatch("updateWalletData", {
            balance: data.data.balance,
            profit: data.data.profit,
            claimed_at: data.data.claimed_at,
          });
          return { status: "success" };
        } else {
          throw new Error(data.message || "Claim failed");
        }
      } finally {
        commit("SET_CLAIM_DISABLED", false);
      }
    },

    updateClaimStatus({ commit, state }) {
      if (!state.claimed_at) {
        commit("SET_REMAINING_TIME", "Claim!");
        commit("SET_CLAIM_DISABLED", false);
        return;
      }

      const timeLeft =
        new Date(`${state.claimed_at}Z`).getTime() + 3600000 - Date.now();

      if (timeLeft <= 0) {
        commit("SET_REMAINING_TIME", "Claim!");
        commit("SET_CLAIM_DISABLED", false);
      } else {
        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        commit("SET_REMAINING_TIME", `${minutes}m ${seconds}s`);
        commit("SET_CLAIM_DISABLED", true);
      }
    },

    startTimer({ commit, dispatch }) {
      const timer = setInterval(() => {
        dispatch("updateClaimStatus");
      }, 1000);
      commit("SET_TIMER", timer);
    },

    clearTimer({ state, commit }) {
      if (state.timer) {
        clearInterval(state.timer);
        commit("SET_TIMER", null);
      }
    },

    setFirstVisit({ commit }, value) {
      commit('SET_FIRST_VISIT', value);
    },

    logout({ commit, dispatch }) {
      dispatch("clearTimer");
      commit("RESET_INITIALIZATION");
      commit("SET_FIRST_VISIT", true); // Reset first visit on logout
    },
  },
});

export default store;