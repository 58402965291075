<template>
  <transition name="alert-fade">
    <div v-if="visible" class="alert-wrapper">
      <div
        class="alert-container"
        :class="[localTheme, { shake: shouldShake }]"
      >
        <div class="alert-icon">
          <svg
            v-if="localTheme === 'success'"
            class="icon success-icon"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
            />
          </svg>
          <svg v-else class="icon error-icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
            />
          </svg>
        </div>
        <span class="alert-message">{{ localMessage }}</span>
        <button class="close-button" @click="hideAlert">
          <svg viewBox="0 0 24 24" class="close-icon">
            <path
              fill="currentColor"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Alert",
  props: {
    message: String,
    theme: String,
  },

  data() {
    return {
      visible: false,
      localMessage: "",
      localTheme: "",
      shouldShake: false,
      timeout: null,
    };
  },

  methods: {
    showAlert(message, theme) {
      // Clear any existing timeout
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      // If alert is already visible, trigger shake animation
      if (this.visible) {
        this.shouldShake = true;
        setTimeout(() => {
          this.shouldShake = false;
        }, 500);
      }

      this.localMessage = message;
      this.localTheme = theme;
      this.visible = true;

      // Set new timeout
      this.timeout = setTimeout(this.hideAlert, 5000);
    },

    hideAlert() {
      this.visible = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
  },

  beforeUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style scoped>
.alert-wrapper {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  pointer-events: none;
  padding: 0 1rem;
}

.alert-container {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  padding: 1rem 1.5rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 400px;
  width: 100%;
  pointer-events: auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.alert-container.success {
  border-left: 4px solid #4caf50;
}

.alert-container.error {
  border-left: 4px solid #ff1493;
}

.alert-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.icon {
  width: 100%;
  height: 100%;
}

.success-icon {
  color: #4caf50;
  animation: bounce 0.5s ease;
}

.error-icon {
  color: #ff1493;
  animation: shake 0.5s ease;
}

.alert-message {
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  flex-grow: 1;
}

.close-button {
  width: 20px;
  height: 20px;
  padding: 0;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: color 0.2s;
}

.close-button:hover {
  color: white;
}

.close-icon {
  width: 100%;
  height: 100%;
}

/* Entrance/Exit animations */
.alert-fade-enter-active {
  animation: slideIn 0.5s ease forwards;
}

.alert-fade-leave-active {
  animation: slideOut 0.5s ease forwards;
}

/* Shake animation for repeated alerts */
.shake {
  animation: shake 0.5s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(2px);
  }
}
</style>
```
