<template>
  <div class="tasks-page">
    <!-- Loading State -->
    <div v-if="loading" class="loading-container">
      <SimpleSpinner message="Loading tasks..." />
    </div>

    <!-- Tasks Content -->
    <div v-else class="tasks-container">
      <!-- Categories Loop -->
      <div 
        v-for="(taskList, category) in tasks" 
        :key="category" 
        class="category-section"
      >
        <!-- Category Header -->
        <div class="category-header">
          <img
            :src="getCategoryIcon(category)"
            class="category-icon"
            alt="category-icon"
          />
          <h2 class="category-title">{{ capitalizeFirstLetter(category) }}</h2>
        </div>

        <!-- Tasks Grid -->
        <div class="tasks-grid">
          <div
            v-for="task in taskList"
            :key="task.id"
            class="task-card"
            :class="{ completed: task.status !== 'active' }"
          >
            <div class="task-content">
              <!-- Task Header -->
              <div class="task-header">
                <div class="task-icon-wrapper">
                  <img
                    :src="task.iconUrl || 'img/menu/icon.png'"
                    alt="task"
                    class="task-icon"
                  />
                </div>
                <div class="task-info">
                  <h3 class="task-name">{{ task.name }}</h3>
                  <div class="task-reward">+{{ task.reward }}</div>
                </div>
              </div>

              <!-- Task Action -->
              <button
                v-if="task.status === 'active'"
                @click="handleTaskCompletion(task.id, task.link, task.status)"
                class="task-button"
              >
                Complete
              </button>
              <div v-else class="status-badge">
                {{ task.status.charAt(0).toUpperCase() + task.status.slice(1) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { inject } from "vue";
import SimpleSpinner from "@/components/SimpleSpinner.vue";

export default {
  name: "Tasks",
  components: { SimpleSpinner },
  data() {
    return {
      tasks: {}, // Holds task categories and tasks
      loading: true, // Loading state
      token: null,
    };
  },
  mounted() {
    this.getTasks();
  },
  setup() {
    const showGlobalAlert = inject("showGlobalAlert");
    return { showGlobalAlert };
  },
  methods: {
    getTasks() {
      this.token = localStorage.getItem("token");
      const apiTasks = "https://cowterest.online/api/tasks";
      axios
        .get(apiTasks, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "success") {
            this.tasks = data.data;
          }
        })
        .catch(() => {
          this.showGlobalAlert("An error has occurred!", "error");
        })
        .finally(() => {
          this.loading = false; // Stop loading after response
        });
    },
    handleTaskCompletion(taskId, taskLink, taskStatus) {
      if (taskStatus === "complete") return;

      window.open(taskLink, "_blank");

      const apiTaskDone = `https://cowterest.online/api/tasks/${taskId}`;
      axios
        .post(
          apiTaskDone,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        )
        .then(() => this.getTasks());
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getCategoryIcon(category) {
      const iconMap = {
        telegram: "img/social/telegram.svg",
        instagram: "img/social/instagram.svg",
        x: "img/social/x.svg",
        youtube: "img/social/youtube.svg",
      };

      return iconMap[category.toLowerCase()] || "img/social/default.svg";
    },
  },
};
</script>


<style scoped>
.tasks-page {
  min-height: 100vh;
  padding: 1rem;
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.tasks-container {
  max-width: 768px;
  margin: 0 auto;
}

.category-section {
  margin-bottom: 2rem;
}

.category-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.category-icon {
  width: 35px;
  height: 35px;
  filter: drop-shadow(0 0 8px rgba(255, 105, 180, 0.5));
}

.category-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  text-shadow: 2px 2px 5px #ff00b3, 2px 2px 10px #ff69b4;
}

.tasks-grid {
  display: grid;
  gap: 1rem;
}

.task-card {
  background: rgba(187, 66, 151, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.2s;
}

.task-card:not(.completed):active {
  transform: scale(0.98);
}

.task-content {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.task-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.task-icon-wrapper {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.task-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 4px rgba(255, 105, 180, 0.5));
}

.task-info {
  flex: 1;
}

.task-name {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.25rem;
}

.task-reward {
  color: #4cdb0a;
  font-weight: bold;
  font-size: 1rem;
  text-shadow: 0 0 8px rgba(76, 219, 10, 0.5);
}

.task-button {
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  color: white;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.2s;
  min-width: 100px;
  text-align: center;
}

.task-button:active {
  transform: scale(0.95);
}

.status-badge {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem 1.25rem;
  border-radius: 12px;
  font-size: 0.875rem;
  min-width: 100px;
  text-align: center;
}

.task-card.completed {
  opacity: 0.7;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.category-section {
  animation: slideIn 0.3s ease-out forwards;
}

@media (max-width: 380px) {
  .task-content {
    padding: 0.75rem;
  }
  
  .task-icon-wrapper {
    width: 40px;
    height: 40px;
  }
  
  .task-name {
    font-size: 0.875rem;
  }
  
  .task-reward {
    font-size: 0.875rem;
  }
  
  .task-button,
  .status-badge {
    padding: 0.5rem 1rem;
    min-width: 90px;
    font-size: 0.875rem;
  }
}
</style>