<template>
  <div class="shop-page">
    <header class="shop-header">
      <h2>Shop</h2>
      <div class="wallet-info" v-if="wallet?.account?.address">
        <img src="img/social/ton.png" class="wallet-icon" alt="TON" />
        <span>Wallet Connected!</span>
      </div>
    </header>

    <div v-if="loading" class="loading-spinner">
      <SimpleSpinner message="Loading items..." />
    </div>

    <div v-else class="shop-container">
      <div class="categories-wrapper">
        <div class="categories">
          <button
            v-for="(_, categoryName) in items"
            :key="categoryName"
            @click="setCategory(categoryName)"
            :class="[
              'category-button',
              { active: currentCategory === categoryName },
            ]"
          >
            {{ categoryName }}
          </button>
        </div>
      </div>

      <div class="items-grid">
        <div
          v-for="item in currentItems"
          :key="item.id"
          class="item-card"
          :class="{ disabled: item.status !== 'active' }"
        >
          <div class="item-content">
            <div class="item-header">
              <h4 class="item-name">{{ item.name }}</h4>
              <span v-if="item.reward_type === 'profit'" class="reward-badge">
                +{{ formatNumber(item.reward) }} L/H
              </span>
            </div>

            <p class="item-description">{{ item.description }}</p>

            <div class="item-footer">
              <div class="price-section">
                <img
                  v-if="item.unit === 'ton'"
                  src="img/social/ton.png"
                  class="currency-icon"
                  alt="TON"
                />
                <img
                  v-else-if="item.unit === 'coin'"
                  src="img/icon.png"
                  class="currency-icon"
                  alt="Coin"
                />
                <span :class="['price-amount', getPriceClass(item.unit)]">
                  <template v-if="item.unit === 'free'">Free</template>
                  <template v-else>
                    {{ formatNumber(item.price) }}
                    <span class="unit">{{ item.unit }}</span>
                  </template>
                </span>
              </div>

              <button
                class="buy-button"
                @click="buyItem(item)"
                :disabled="item.status !== 'active'"
              >
                {{ item.status === "active" ? "Buy Now" : item.status }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleSpinner from "@/components/SimpleSpinner.vue";
import { useTonWallet } from "@townsquarelabs/ui-vue";
import { inject } from "vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Shop",
  components: { SimpleSpinner },

  data() {
    return {
      wallet: useTonWallet(),
      tonConnectUI: inject("tonConnectUI"),
      showGlobalAlert: inject("showGlobalAlert"),
    };
  },

  computed: {
    ...mapState("shop", ["items", "loading", "currentCategory"]),
    ...mapGetters("shop", ["currentItems"]),
  },

  async created() {
    try {
      await this.$store.dispatch("shop/fetchItems");
    } catch {
      this.showGlobalAlert("Failed to load items!", "error");
    }
  },

  methods: {
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatBalance(balance) {
      return (balance / 1e9).toFixed(2);
    },

    getPriceClass(unit) {
      return {
        "price-ton": unit === "ton",
        "price-coin": unit === "coin",
        "price-free": unit === "free",
      };
    },

    setCategory(category) {
      this.$store.dispatch("shop/setCategory", category);
    },

    async buyItem(item) {
      const buyMethods = {
        ton: this.buyWithTon,
        free: this.freeBuy,
        coin: this.buyWithCoin,
      };

      try {
        await buyMethods[item.unit]?.(item);
      } catch (error) {
        console.error("Purchase error:", error);
      }
    },

    async buyWithTon(item) {
      if (!this.wallet?.account?.address) {
        this.showGlobalAlert("Please connect your wallet first!", "error");
        return;
      }

      if (!this.tonConnectUI) {
        this.showGlobalAlert(
          "TON Connect is not properly initialized.",
          "error"
        );
        return;
      }

      try {
        const myTransaction = {
          validUntil: Math.floor(Date.now() / 1000) + 300,
          messages: [
            {
              address: "UQBtIEUz6bfam3vcDo3BHT8Nw7vk8KNkfK8LnrNlbgS-Ab4N",
              amount: BigInt(Math.floor(item.price * 1e9)).toString(),
            },
          ],
        };

        const response = await this.tonConnectUI.sendTransaction(myTransaction);

        if (!response?.boc) {
          throw new Error("Transaction response is invalid");
        }

        await this.$store.dispatch("shop/purchaseItem", {
          item,
          boc: response.boc,
        });

        this.showGlobalAlert(
          `Transaction successful! You bought ${item.name}.`,
          "success"
        );
      } catch (error) {
        this.handleTransactionError(error);
      }
    },

    async freeBuy(item) {
      try {
        await this.$store.dispatch("shop/purchaseItem", { item });
        this.showGlobalAlert(
          `You have successfully claimed ${item.name} for free!`,
          "success"
        );
      } catch {
        this.showGlobalAlert("Failed to claim item.", "error");
      }
    },

    // In your component method
    async buyWithCoin(item) {
      try {
        await this.$store.dispatch("shop/purchaseItem", { item });

        const currentBalance = Number(this.$store.state.balance);
        const itemPrice = Number(item.price);
        const currentProfit = Number(this.$store.state.profit);
        const itemReward = Number(item.reward);

        // Calculate new values
        const newBalance = currentBalance - itemPrice;
        const newProfit = currentProfit + itemReward;

        // Update both balance and profit
        await this.$store.dispatch("updateWalletData", {
          balance: newBalance,
          profit: newProfit,
          claimed_at: this.$store.state.claimed_at,
        });

        this.showGlobalAlert(
          `You have successfully bought ${item.name}!`,
          "success"
        );
      } catch (error) {
        this.showGlobalAlert(
          error.response?.data?.message || "Failed to purchase item.",
          "error"
        );
      }
    },

    handleTransactionError(error) {
      if (error.message?.includes("TON_CONNECT_SDK_ERROR")) {
        if (error.message.includes("User rejected")) {
          this.showGlobalAlert("Transaction was cancelled by user", "error");
        } else if (error.message.includes("Timeout")) {
          this.showGlobalAlert(
            "Transaction timed out. Please try again",
            "error"
          );
        } else if (error.message.includes("Insufficient funds")) {
          this.showGlobalAlert("Insufficient funds in your wallet", "error");
        } else {
          this.showGlobalAlert(
            "Please check your wallet and try again",
            "error"
          );
        }
      } else {
        this.showGlobalAlert("Transaction failed: Unknown error", "error");
      }
    },
  },
};
</script>

<style scoped>
.shop-page {
  min-height: 100vh;
  padding-bottom: env(safe-area-inset-bottom);
}

.shop-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-header h2 {
  color: #ff69b4;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.wallet-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  color: white;
}

.wallet-icon {
  width: 20px;
  height: 20px;
}

.categories-wrapper {
  position: sticky;
  top: 60px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.75rem;
  margin: 0 -1rem;
}

.categories {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 4px;
  -webkit-overflow-scrolling: touch;
  margin-left: 15px;
  margin-right: 15px;
  scrollbar-width: none;
}

.category-button {
  background: rgba(4, 4, 4, 0.1);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  white-space: nowrap;
  transition: all 0.2s;
}

.category-button.active {
  background: #ff69b4;
}

.items-grid {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  margin-bottom: 90px;
}

.item-card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.2s;
  margin-bottom: 10px;
}

.item-card:not(.disabled):active {
  transform: scale(0.98);
}

.item-content {
  padding: 1rem;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.item-name {
  font-size: 1.125rem;
  font-weight: 600;
  color: black;
  margin: 0;
}

.reward-badge {
  background: #28a745;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
}

.item-description {
  color: #666;
  font-size: 0.875rem;
  margin: 0.5rem 0 1rem;
}

.item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.currency-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.price-amount {
  font-weight: 600;
  font-size: 1.125rem;
}

.price-ton {
  color: #0088cc;
}
.price-coin {
  color: #ff69b4;
}
.price-free {
  color: #28a745;
}

.unit {
  font-size: 0.75rem;
  opacity: 0.7;
  margin-left: 0.25rem;
}

.buy-button {
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  color: white;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.2s;
}

.buy-button:disabled {
  background: #999;
  opacity: 0.5;
  cursor: not-allowed;
}

.buy-button:not(:disabled):active {
  transform: scale(0.95);
  background: linear-gradient(135deg, #ff1493, #ff69b4);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.shop-container {
  max-width: 768px;
  margin: 0 auto;
}

@media (max-width: 380px) {
  .shop-header h2 {
    font-size: 1.25rem;
  }

  .item-content {
    padding: 0.75rem;
  }

  .buy-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
</style>
