import axios from 'axios';

export default {
  namespaced: true,
  
  state: {
    leaderboard: [],
    userRank: null,
    loading: false,
    lastFetch: null,
    cacheTimeout: 5 * 60 * 1000 // 5 minutes
  },

  getters: {
    shouldFetch: (state) => {
      if (!state.lastFetch) return true;
      return Date.now() - state.lastFetch > state.cacheTimeout;
    }
  },

  mutations: {
    SET_LEADERBOARD(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    SET_USER_RANK(state, userRank) {
      state.userRank = userRank;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    UPDATE_LAST_FETCH(state) {
      state.lastFetch = Date.now();
    }
  },

  actions: {
    async fetchLeaderboard({ commit, getters, state }) {
      if (!getters.shouldFetch && state.leaderboard.length > 0) {
        return;
      }

      try {
        commit('SET_LOADING', true);
        const token = localStorage.getItem('token');
        const response = await axios.get('https://cowterest.online/api/leaders', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.status === 'success') {
          commit('SET_LEADERBOARD', response.data.data.leaderboard);
          commit('SET_USER_RANK', response.data.data.user_rank);
          commit('UPDATE_LAST_FETCH');
        }
      } catch (error) {
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};