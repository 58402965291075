import axios from 'axios';

export default {
  namespaced: true,

  state: {
    friends: [],
    friendsCount: 0,
    referralLink: '',
    loading: false,
    lastFetch: null,
    cacheTimeout: 5 * 60 * 1000 // 5 minutes cache
  },

  getters: {
    shouldFetch: (state) => {
      if (!state.lastFetch) return true;
      if (state.friends.length === 0) return true;
      return Date.now() - state.lastFetch > state.cacheTimeout;
    },

    telegramShareLink: (state) => {
      return `https://t.me/share/url?url=${encodeURIComponent(state.referralLink)}&text=🐄 Join me on Cowterest! Milk the opportunity and earn $COWT. Don’t miss the stampede! The herd is growing fast! 🌿💸🍼`; 
    }
  },

  mutations: {
    SET_FRIENDS(state, friends) {
      state.friends = friends;
    },
    SET_FRIENDS_COUNT(state, count) {
      state.friendsCount = count;
    },
    SET_REFERRAL_LINK(state, link) {
      state.referralLink = `t.me/CowterestBot/cowt?startapp=${link}`;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    UPDATE_LAST_FETCH(state) {
      state.lastFetch = Date.now();
    },
    RESET_STATE(state) {
      state.friends = [];
      state.friendsCount = 0;
      state.referralLink = '';
      state.lastFetch = null;
    }
  },

  actions: {
    async fetchFriends({ commit, getters, state }) {
      // Return immediately if we have cached data
      if (!getters.shouldFetch) {
        return;
      }

      try {
        commit('SET_LOADING', true);
        const token = localStorage.getItem('token');
        const response = await axios.get('https://cowterest.online/api/friends', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.data.status === 'success') {
          const { friends, friends_count, referral_link } = response.data.data;
          
          const processedFriends = friends.map(friend => ({
            first_name: friend.first_name,
            last_name: friend.last_name || '',
            id: friend.id
          }));

          commit('SET_FRIENDS', processedFriends);
          commit('SET_FRIENDS_COUNT', friends_count);
          commit('SET_REFERRAL_LINK', referral_link);
          commit('UPDATE_LAST_FETCH');
        }
      } catch (error) {
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    resetFriendsData({ commit }) {
      commit('RESET_STATE');
    }
  }
};