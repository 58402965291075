<!-- SimpleSpinner.vue -->
<template>
    <div class="spinner-container">
      <div class="spinner"></div>
      <p class="text-light" v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "SimpleSpinner",
    props: {
      message: {
        type: String,
        default: "loading", 
      },
    },
  };
  </script>
  
  <style scoped>
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
  }
  
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #ff80bf; /* Spinner color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  