<template>
  <div class="leaderboard-page">
    <div v-if="loading" class="loading-spinner">
      <SimpleSpinner message="Loading leaders..." />
    </div>

    <main v-else class="main-content">
      <h1 class="page-title">Leaderboard</h1>

      <!-- User Stats Card -->
      <div v-if="userRank" class="user-stats-card">
        <div class="user-info">
          <div class="avatar">
            <span>You</span>
          </div>
          <div class="stats">
            <h3 class="name">{{ userRank.name }}</h3>
            <div class="metrics">
              <span class="metric">
                {{ formatNumber(userRank.balance) }}
              </span>
              +
              <span class="metric">
                {{ formatNumber(userRank.profit) }} L/H
              </span>
              +
              <span class="metric">
                {{ Math.max(0, Math.min(100, userRank.shop_count)) }}
              </span>
            </div>
          </div>
        </div>
        <div class="rank">#{{ userRank.rank }}</div>
      </div>

      <!-- Boost Button -->
      <router-link to="/shop" class="boost-button">
        <span>Boost Your Rank</span>
      </router-link>

      <div class="divider"></div>

      <!-- Leaderboard List -->
      <div class="leaderboard-list">
        <div 
          v-for="leader in leaderboard" 
          :key="leader.user_id"
          class="leader-card"
        >
          <div class="leader-info">
            <div class="avatar">
              <img src="img/menu/icon.png" alt="cowterest" />
            </div>
            <div class="stats">
              <h3 class="name">{{ leader.name }}</h3>
              <div class="metrics">
                <span class="metric">
                  {{ formatNumber(leader.balance) }}
                </span>
                +
                <span class="metric">
                  {{ formatNumber(leader.profit) }} L/H
                </span>
                +
                <span class="metric">
                  {{ leader.shop_count }}
                </span>
              </div>
            </div>
          </div>
          <div class="rank">#{{ leader.rank }}</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SimpleSpinner from '@/components/SimpleSpinner.vue';
import { mapState } from 'vuex';
import { inject } from 'vue';

export default {
  name: 'Leaderboard',
  components: { SimpleSpinner },

  computed: {
    ...mapState('leaderboard', ['leaderboard', 'userRank', 'loading']),
  },

  setup() {
    const showGlobalAlert = inject('showGlobalAlert');
    return { showGlobalAlert };
  },

  async created() {
    try {
      await this.$store.dispatch('leaderboard/fetchLeaderboard');
    } catch (error) {
      this.showGlobalAlert('Failed to load leaderboard!', 'error');
    }
  },

  methods: {
    formatNumber(num) {
      return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num.toLocaleString();
    }
  }
};
</script>

<style scoped>
.leaderboard-page {
  min-height: 100vh;
  padding: 1rem;
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(255, 105, 180, 0.5);
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.user-stats-card {
  background: rgba(187, 66, 151, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.boost-button {
  display: block;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 12px;
  font-weight: bold;
  text-decoration: none;
  margin: 1.5rem 0;
  transition: transform 0.2s;
}

.boost-button:active {
  transform: scale(0.98);
}

.divider {
  height: 4px;
  background: linear-gradient(90deg, transparent, #ff80bf, transparent);
  margin: 1.5rem 0;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(255, 128, 191, 0.5);
}

.leaderboard-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.leader-card {
  background: rgba(145, 51, 117, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leader-card:nth-child(odd) {
  background: rgba(187, 66, 151, 0.7);
}

.user-info,
.leader-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.name {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.metrics {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.metric {
  color: #ff80bf;
  font-weight: bold;
}

.rank {
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}

@media (max-width: 380px) {
  .metrics {
    font-size: 0.75rem;
  }
  
  .name {
    font-size: 0.875rem;
  }
}
</style>