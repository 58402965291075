```vue
<template>
  <div class="loading-container">
    <div class="loading-content">
      <!-- Cow Logo -->
      <div class="logo-container">
        <img src="img/menu/icon.png" alt="Cowterest" class="app-logo pulse" />
      </div>

      <!-- Loading Animation -->
      <div class="loading-animation">
        <div class="milk-wave"></div>
        <div class="milk-wave delay-1"></div>
        <div class="milk-wave delay-2"></div>
      </div>

      <!-- Loading Text -->
      <div class="loading-text">
        <span class="text-gradient">{{ loadingText }}</span>
      </div>

      <!-- Progress Bar -->
      <div class="progress-bar">
        <div class="progress-fill"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
  data() {
    return {
      loadingTexts: [
        'Milking the blockchain...',
        'Gathering fresh data...',
        'Preparing your rewards...',
        'Moo-ving things around...'
      ],
      currentTextIndex: 0
    };
  },
  computed: {
    loadingText() {
      return this.loadingTexts[this.currentTextIndex];
    }
  },
  mounted() {
    this.startTextRotation();
  },
  beforeUnmount() {
    if (this.textInterval) {
      clearInterval(this.textInterval);
    }
  },
  methods: {
    startTextRotation() {
      this.textInterval = setInterval(() => {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.loadingTexts.length;
      }, 2000);
    }
  }
};
</script>

<style scoped>
.loading-container {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, #1a1a1a, #2d2d2d);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.logo-container {
  width: 120px;
  height: 120px;
  position: relative;
}

.app-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.loading-animation {
  position: relative;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.milk-wave {
  width: 8px;
  height: 40px;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  border-radius: 4px;
  animation: wave 1s ease-in-out infinite;
}

.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

@keyframes wave {
  0%, 100% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
}

.loading-text {
  font-size: 1.25rem;
  font-weight: bold;
  min-height: 1.5em;
}

.text-gradient {
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  from {
    background-position: 200% center;
  }
  to {
    background-position: -200% center;
  }
}

.progress-bar {
  width: 200px;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #ff69b4, #ff1493);
  animation: fill 2s ease infinite;
  transform-origin: left;
}

@keyframes fill {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(1);
  }
}

/* Glass effect for modern browsers */
.loading-content::before {
  content: '';
  position: absolute;
  inset: -5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  z-index: -1;
}

/* Mobile Optimization */
@media (max-width: 380px) {
  .logo-container {
    width: 100px;
    height: 100px;
  }

  .loading-text {
    font-size: 1rem;
  }

  .loading-animation,
  .progress-bar {
    width: 160px;
  }
}
</style>
```