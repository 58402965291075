<template>
  <div class="friends-page">
    <!-- Invite Section -->
    <transition name="fade-slide-down" appear>
      <div class="invite-section">
        <h1 class="page-title glow-text">Invite Friends!</h1>
        <p class="page-subtitle">You and your friend will receive bonuses</p>

        <!-- Reward Cards -->
        <transition-group 
          name="list" 
          tag="div" 
          class="rewards-grid"
          appear
        >
          <!-- Regular Invite Card -->
          <div class="reward-card" key="regular">
            <div class="reward-icon pulse">
              <img src="img/menu/gift.svg" alt="Gift Icon" />
            </div>
            <div class="reward-content">
              <h3>Invite a friend</h3>
              <span class="reward-amount shine">+10.00K</span>
              <p>for you and your friend</p>
            </div>
          </div>

          <!-- Premium Invite Card -->
          <div class="reward-card premium" key="premium">
            <div class="reward-icon pulse">
              <img src="img/menu/reward2.svg" alt="Premium Gift" />
            </div>
            <div class="reward-content">
              <h3>Invite Premium Friend</h3>
              <span class="reward-amount shine">+25.00K</span>
              <p>for you and your friend</p>
            </div>
          </div>
        </transition-group>

        <!-- Action Buttons -->
        <transition name="fade-up" appear>
          <div class="action-buttons">
            <button @click="inviteFriends" class="invite-button hover-shine">
              <span>Invite Friends</span>
            </button>
            <button class="copy-button hover-rotate" @click="copyLink">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </button>
          </div>
        </transition>

        <!-- Friends List Section -->
        <transition name="fade" appear>
          <div class="friends-section">
            <div class="section-header">
              <h2 class="glow-text">Your Friends ({{ friendsCount }})</h2>
            </div>

            <div v-if="loading" class="loading-state">
              <SimpleSpinner message="Loading friends..." />
            </div>

            <transition name="fade" mode="out-in">
              <div v-if="!loading && friendsCount === 0" class="empty-state">
                <img src="img/menu/invite.svg" alt="No friends yet" class="empty-icon pulse" />
                <p>You haven't invited anyone yet</p>
              </div>

              <transition-group 
                v-else 
                name="list" 
                tag="div" 
                class="friends-list"
              >
                <div
                  v-for="(friend, index) in friends"
                  :key="friend.id || index"
                  class="friend-card hover-lift"
                  :style="{ animationDelay: `${index * 0.1}s` }"
                >
                  <div class="friend-avatar pulse">
                    <img src="img/menu/invite.svg" alt="Friend" />
                  </div>
                  <div class="friend-info">
                    <h3>{{ friend.first_name }} {{ friend.last_name || "" }}</h3>
                  </div>
                </div>
              </transition-group>
            </transition>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapState, mapGetters } from 'vuex';
import ClipboardJS from 'clipboard';
import SimpleSpinner from '@/components/SimpleSpinner.vue';

export default {
  name: 'Friends',
  components: { SimpleSpinner },

  setup() {
    const showGlobalAlert = inject('showGlobalAlert');
    return { showGlobalAlert };
  },

  computed: {
    ...mapState('friends', [
      'friends',
      'friendsCount',
      'referralLink',
      'loading',
      'lastFetch'
    ]),
    ...mapGetters('friends', [
      'telegramShareLink',
      'shouldFetch'
    ])
  },

  async created() {
    // Only fetch if we should (no cache or cache expired)
    if (this.shouldFetch) {
      try {
        await this.initializeData();
      } catch (error) {
        this.showGlobalAlert('Failed to load friends data!', 'error');
      }
    }
  },

  mounted() {
    this.initClipboard();
  },

  methods: {
    async initializeData() {
      await this.$store.dispatch('friends/fetchFriends');
    },

    initClipboard() {
      const clipboard = new ClipboardJS('.copy-button', {
        text: () => this.referralLink
      });

      clipboard.on('success', () => {
        this.showGlobalAlert('Referral link copied to clipboard!', 'success');
      });

      clipboard.on('error', () => {
        this.showGlobalAlert('Failed to copy the referral link!', 'error');
      });
    },

    inviteFriends() {
      window.open(this.telegramShareLink, '_blank');
    },

    copyLink() {
      const clipboard = new ClipboardJS(".btn-copy", {
        text: () => this.referralLink,
      });

      clipboard.on("success", () => {
        this.showGlobalAlert("Referral link copied to clipboard!", "success");
      });

      clipboard.on("error", () => {
        this.showGlobalAlert("Failed to copy the referral link!", "error");
      });
        }
  }
};
</script>

<style scoped>
.friends-page {
  min-height: 100vh;
  padding: 1rem;
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 5px #ff00b3, 2px 2px 10px #ff69b4;
}

.page-subtitle {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.rewards-grid {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.reward-card {
  background: rgba(187, 66, 151, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: transform 0.2s;
}

.reward-card:active {
  transform: scale(0.98);
}

.reward-card.premium {
  background: rgba(255, 105, 180, 0.7);
}

.reward-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounce 2s infinite;
}

.reward-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 8px rgba(255, 105, 180, 0.5));
}

.reward-content {
  flex: 1;
}

.reward-content h3 {
  color: white;
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 0.25rem;
}

.reward-amount {
  color: #4cdb0a;
  font-size: 1.25rem;
  font-weight: bold;
  text-shadow: 0 0 8px rgba(76, 219, 10, 0.5);
}

.reward-content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  margin: 0;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.invite-button {
  flex: 1;
  background: linear-gradient(135deg, #ff69b4, #ff1493);
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 12px;
  font-weight: bold;
  transition: all 0.2s;
}

.copy-button {
  width: 48px;
  height: 48px;
  background: rgba(187, 66, 151, 0.7);
  border: none;
  border-radius: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.invite-button:active,
.copy-button:active {
  transform: scale(0.95);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section-header h2 {
  color: white;
  font-size: 1.25rem;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(255, 105, 180, 0.5);
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: rgba(255, 255, 255, 0.8);
}

.empty-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
  opacity: 0.5;
}

.friends-list {
  display: grid;
  gap: 1rem;
}

.friend-card {
  background: rgba(187, 66, 151, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 16px;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.friend-avatar {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.friend-avatar img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.friend-info h3 {
  color: white;
  font-size: 1rem;
  margin: 0;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@media (max-width: 380px) {
  .page-title {
    font-size: 1.75rem;
  }
  
  .reward-card {
    padding: 1rem;
  }
  
  .reward-icon {
    width: 40px;
    height: 40px;
  }
  
  .reward-content h3 {
    font-size: 1rem;
  }
  
  .reward-amount {
    font-size: 1.1rem;
  }
}
/* Fade and Slide Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-slide-down-enter-active {
  transition: all 0.5s ease;
}

.fade-slide-down-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-up-enter-active {
  transition: all 0.5s ease;
  transition-delay: 0.2s;
}

.fade-up-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

/* List Animations */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* Pulse Animation */
.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Shine Effect */
.shine {
  position: relative;
  overflow: hidden;
}

.shine::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(45deg) translateX(-150%);
  }
  50% {
    transform: rotate(45deg) translateX(150%);
  }
  100% {
    transform: rotate(45deg) translateX(150%);
  }
}

/* Hover Effects */
.hover-shine {
  position: relative;
  overflow: hidden;
}

.hover-shine::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  transition: 0.3s;
}

.hover-shine:hover::after {
  animation: button-shine 0.5s forwards;
}

@keyframes button-shine {
  0% {
    transform: rotate(45deg) translateX(-150%);
  }
  100% {
    transform: rotate(45deg) translateX(150%);
  }
}

.hover-rotate {
  transition: transform 0.3s ease;
}

.hover-rotate:hover {
  transform: rotate(15deg);
}

.hover-lift {
  transition: transform 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-2px);
}

/* Glowing Text */
.glow-text {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 2px 2px 5px #ff00b3,
                 2px 2px 10px #ff69b4;
  }
  to {
    text-shadow: 2px 2px 8px #ff00b3,
                 2px 2px 15px #ff69b4;
  }
}

/* Card Entrance Animation */
.friend-card {
  animation: slideIn 0.5s ease-out forwards;
  opacity: 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Button Press Animation */
.invite-button:active,
.copy-button:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

/* Reward Amount Pulse */
.reward-amount {
  animation: textPulse 2s infinite;
}

@keyframes textPulse {
  0% {
    text-shadow: 0 0 8px rgba(76, 219, 10, 0.5);
  }
  50% {
    text-shadow: 0 0 15px rgba(76, 219, 10, 0.8);
  }
  100% {
    text-shadow: 0 0 8px rgba(76, 219, 10, 0.5);
  }
}
</style>