<template>
  <div class="balance-card">
    <div class="card-content">
      <div class="balance-header">
        <div class="balance-icon">
          <img 
            src="img/menu/bottle.svg" 
            alt="bottle" 
            class="bottle-icon"
          />
        </div>
        <div class="balance-info">
          <h2 class="balance-amount">{{ formattedBalance }}</h2>
          <div class="balance-label">Current Balance</div>
        </div>
      </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "BalanceCard",
  props: {
    formattedBalance: {
      type: String,
      required: true
    }
  },  
  methods: {
    formatNumber(num) {
      return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num.toLocaleString();
    }
  }
}
</script>

<style scoped>
.balance-card {
  background: rgba(187, 66, 151, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 1.5rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  margin-left: 20px;
  margin-right: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.balance-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.balance-icon {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottle-icon {
  width: 50px;
  height: 30px;
  filter: drop-shadow(0 0 8px rgba(255, 105, 180, 0.5));
}

.balance-info {
  flex: 1;
}

.balance-amount {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(255, 105, 180, 0.5);
}

.balance-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.balance-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.stat-divider {
  width: 1px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 1rem;
}

.stat-icon {
  width: 24px;
  height: 24px;
}

.stat-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 4px rgba(255, 105, 180, 0.5));
}

.stat-value {
  color: white;
  font-weight: bold;
  font-size: 1.25rem;
}

.stat-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75rem;
}

@media (max-width: 380px) {
  .balance-card {
    padding: 1rem;
  }
  
  .balance-amount {
    font-size: 1.75rem;
  }
  
  .stat-value {
    font-size: 1rem;
  }
}
</style>