<template>
  <Alert ref="alertComponent" />

  <TonConnectUIProvider :options="options">
    <Header />
    <router-view />
    <Footer />
  </TonConnectUIProvider>
</template>

<script>
import { ref } from 'vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Alert from './components/Alert.vue';
import { TonConnectUIProvider } from '@townsquarelabs/ui-vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Alert,
    TonConnectUIProvider,
  },
  setup() {
    // Define options for TonConnect
    const options = {
      manifestUrl: "https://cowterest.online/tonconnect-manifest.json",
    };

    return { options };
  },
  provide() {
    // Provide global alert method to all components
    return {
      showGlobalAlert: this.showGlobalAlert,
    };
  },
  methods: {
    showGlobalAlert(message, theme) {
      this.$refs.alertComponent?.showAlert(message, theme);
    },
    fixScrollingIssue() {
      window.addEventListener(
        'touchmove',
        (e) => {
          const scrollTop = window.scrollY || document.documentElement.scrollTop;
          if (scrollTop <= 0) {
            e.preventDefault();
            window.scrollTo(0, 1); // Prevents scrolling back to the top
          }
        },
        { passive: false }
      );
    },
  },
  mounted() {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();

      this.fixScrollingIssue();
    }
  },
};
</script>
