import axios from 'axios';

export default {
  namespaced: true,
  
  state: {
    items: {},
    loading: false,
    currentCategory: '',
  },

  getters: {
    currentItems: (state) => state.currentCategory ? state.items[state.currentCategory] : []
  },

  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_CATEGORY(state, category) {
      state.currentCategory = category;
    }
  },

  actions: {
    async fetchItems({ commit, state }) {
      try {
        commit('SET_LOADING', true);
        const token = localStorage.getItem('token');
        const response = await axios.get('https://cowterest.online/api/shop', {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.status === 'success') {
          commit('SET_ITEMS', response.data.data);
          if (!state.currentCategory) {
            commit('SET_CATEGORY', Object.keys(response.data.data)[0]);
          }
        }
      } catch (error) {
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    setCategory({ commit }, category) {
      commit('SET_CATEGORY', category);
    },

    async purchaseItem({ dispatch }, { item, boc = null }) {
      const token = localStorage.getItem('token');
      try {
        await axios.post(
          'https://cowterest.online/api/shop/items',
          {
            item_id: item.id,
            boc,
            wallet_address: null
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        await dispatch('fetchItems');
      } catch (error) {
        throw error;
      }
    }
  }
};