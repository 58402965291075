```vue
<template>
  <footer class="footer">
    <div class="nav-container">
      <router-link
        v-for="item in navItems"
        :key="item.name"
        :to="{ name: item.route }"
        class="nav-item"
        :class="{ 'nav-item-active': $route.name === item.route }"
      >
        <div class="icon-wrapper">
          <img :src="item.icon" :alt="item.label" />
          <div class="glow"></div>
        </div>
        <span class="nav-label">{{ item.label }}</span>
      </router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      navItems: [
        { label: 'Home', route: 'home', icon: 'img/icon/home.svg' },
        { label: 'Friends', route: 'friends', icon: 'img/icon/friends.svg' },
        { label: 'Earn', route: 'tasks', icon: 'img/icon/earn.svg' },
        { label: 'Shop', route: 'shop', icon: 'img/icon/shop.svg' },
        { label: 'Leaders', route: 'leader', icon: 'img/icon/leader.svg' }
      ]
    }
  }
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.nav-container {
  background: rgba(187, 66, 151, 0.85);
  backdrop-filter: blur(900px);
  border-radius: 20px 20px 0 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 -4px 20px rgba(255, 105, 180, 0.15);
}

.nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem;
  flex: 1;
  transition: transform 0.2s;
}

.nav-item:active {
  transform: scale(0.95);
}

.icon-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.icon-wrapper img {
  width: 28px;
  height: 28px;
  transition: all 0.3s;
  z-index: 1;
  opacity: 0.8;
}

.glow {
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 105, 180, 0.3) 0%, transparent 70%);
  opacity: 0;
  transition: all 0.3s;
}

.nav-label {
  font-size: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  transition: all 0.3s;
  text-shadow: 2px 2px 4px rgba(255, 105, 180, 0.3);
}

/* Active State */
.nav-item-active .icon-wrapper img {
  transform: translateY(-2px);
  opacity: 1;
  filter: drop-shadow(0 2px 4px rgba(255, 105, 180, 0.5));
}

.nav-item-active .glow {
  opacity: 1;
  animation: pulse 2s infinite;
}

.nav-item-active .nav-label {
  color: #ffffff;
  text-shadow: 2px 2px 5px #ff00b3, 
               2px 2px 10px #ff69b4;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    background: radial-gradient(circle, rgba(255, 105, 180, 0.2) 0%, transparent 70%);
  }
  50% {
    transform: scale(1.05);
    background: radial-gradient(circle, rgba(255, 105, 180, 0.4) 0%, transparent 70%);
  }
  100% {
    transform: scale(0.95);
    background: radial-gradient(circle, rgba(255, 105, 180, 0.2) 0%, transparent 70%);
  }
}

/* Hover Effects */
.nav-item:not(.nav-item-active):hover .icon-wrapper img {
  opacity: 0.9;
  transform: translateY(-1px);
}

.nav-item:not(.nav-item-active):hover .nav-label {
  color: rgba(255, 255, 255, 0.95);
}


</style>
```