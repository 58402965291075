<template>
  <main class="main-content">
    <div class="container">
      <cow @click="moreCows" v-for="(cow, index) in cows" :key="index"></cow>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Reward',
  data() {
    return {
      cows: [1],
    };
  },
  methods: {
    moreCows() {
      this.cows.push(this.cows.length + 1);
    }
  }
}
</script>

<style scoped>
main {
  height: 100vh;
  overflow: hidden;
  background-color: #002;

  background-image: radial-gradient(circle, white, transparent 3px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, white, transparent 1px),
  radial-gradient(circle, white, transparent 3px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, white, transparent 1px),
  radial-gradient(circle, white, transparent 1.5px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, white, transparent 1px),
  radial-gradient(circle, white, transparent 1.5px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, white, transparent 1px),
  radial-gradient(circle, white, transparent 1.5px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, aliceblue, transparent 1px),
  radial-gradient(circle, lightyellow, transparent 1.5px),
  radial-gradient(circle, white, transparent 2px),
  radial-gradient(circle, linen, transparent 1px);
  background-size: 60px 70px, 81px 95px, 87px 61px,
  78px 77px, 73px 87px, 65px 93px,
  50px 67px, 93px 87px, 65px 83px,
  87px 80px, 67px 77px, 59px 97px,
  60px 73px, 81px 65px, 87px 81px,
  65px 80px, 67px 90px, 59px 67px,
  cover;
  background-position: 50px 80px, 67px 50px, 59px 57px,
  60px 20px, 81px 65px, 87px 51px,
  50px 37px, 43px 87px, 65px 3px,
  50px 80px, 67px 50px, 59px 57px,
  60px 20px, 81px 65px, 87px 51px,
  50px 37px, 43px 87px, 65px 3px,
  top;
}

cow {
  display: block;
  position: absolute;
  left: 10em;
  top: 10em;
  animation: transform1 7s infinite alternate;

  width: 9em;
  height: 6em;
  border-radius: 3em;
  color: white;
  background-color: currentColor;
  background-image: radial-gradient(black 27%, transparent 0),
  radial-gradient(black 23%, transparent 0),
  radial-gradient(black 40%, transparent 0),
  radial-gradient(black 16%, transparent 0),
  radial-gradient(black 21%, transparent 0),
  radial-gradient(black 17%, transparent 0),
  radial-gradient(black 19%, transparent 0),
  radial-gradient(black 27%, transparent 0),
  radial-gradient(black 23%, transparent 0),
  radial-gradient(black 40%, transparent 0),
  radial-gradient(black 16%, transparent 0),
  radial-gradient(black 21%, transparent 0),
  radial-gradient(black 17%, transparent 0),
  radial-gradient(black 19%, transparent 0);
  background-size: 60% 80%, 73% 47%, 67% 87%, 57% 65%, 78% 64%;
  background-position: 30% 20%, 27% 37%, 33% 47%, -22% 36%;
  box-shadow: inset 0 0 0.5em rgba(0, 0, 0, 0.5);
}

cow::before, cow::after {
  content: "";
  display: block;
  position: absolute;
}

cow::before {
  width: 4em;
  height: 5em;
  top: -2em;
  left: -2em;
  border-radius: 1em 1em 2em 2em/1em 1em 4em 4em;
  border-style: solid;
  border-width: 0.1em 0.8em 1em;
  border-color: transparent currentColor rgba(40, 40, 40, 0.5);
  background-clip: padding-box;
  background-color: inherit;
  background-image: radial-gradient(closest-side, black 40%, white 0, white 90%, grey, transparent),
  radial-gradient(closest-side, black 40%, white 0, white 90%, grey, transparent),
  radial-gradient(closest-side, rgba(0, 0, 0, 0.7) 80%, transparent),
  radial-gradient(closest-side, rgba(0, 0, 0, 0.7) 80%, transparent),
  radial-gradient(farthest-side at center 25%, salmon, darkSalmon 95%, transparent);
  background-size: 40% 40%, 40% 40%, 15% 15%, 15% 15%, 50% 28%;
  background-repeat: no-repeat;
  background-position: 25% 40%, 75% 40%,
  40% 90%, 60% 90%, bottom;
  box-shadow: 0.5em 0.5em 0.5em rgba(40, 40, 40, 0.5),
  inset 0 0 0.5em rgba(0, 0, 0, 0.5);

  animation: headbob 3s infinite;
  animation-delay: inherit;
}

cow::after {
  height: 3em;
  width: 5em;
  top: 95%;
  left: 25%;
  background-image: linear-gradient(to right, black 5%, #444 25%, black 35%, #444 50%, black 70%),
  linear-gradient(to right, rgba(0, 0, 0, 0.2),
      transparent 20%, transparent 80%, rgba(0, 0, 0, 0.2)),
  linear-gradient(currentColor, currentColor),
  linear-gradient(to right, black 5%, #444 25%, black 35%, #444 50%, black 70%),
  linear-gradient(to right, rgba(0, 0, 0, 0.2),
      transparent 20%, transparent 80%, rgba(0, 0, 0, 0.2)),
  linear-gradient(currentColor, currentColor),
  linear-gradient(to right, black 5%, #444 25%, black 35%, #444 50%, black 70%),
  linear-gradient(to right, rgba(0, 0, 0, 0.2),
      transparent 20%, transparent 80%, rgba(0, 0, 0, 0.2)),
  linear-gradient(currentColor, currentColor),
  linear-gradient(to right, black 5%, #444 25%, black 35%, #444 50%, black 70%),
  linear-gradient(to right, rgba(0, 0, 0, 0.2),
      transparent 20%, transparent 80%, rgba(0, 0, 0, 0.2)),
  linear-gradient(currentColor, currentColor);
  background-size: 1em 0.5em, 1em 100%, 1em 100%;
  background-repeat: no-repeat;
  background-position: 0% bottom, 0% center, 0% center,
  80% bottom, 80% center, 80% center,
  10% bottom, 10% center, 10% center,
  90% bottom, 90% center, 90% center;
}

cow:nth-of-type(5n - 1) {
  color: saddleBrown;
  background: radial-gradient(transparent 60%, rgba(0, 0, 0, 0.5)) currentColor;
  background-size: cover !important;
}

cow:nth-of-type(2n) {
  top: 50%;
  background-position: 27% 37%, 30% 24%, 39% 47%, -22% 36%;
}

cow:nth-of-type(3n) {
  top: 10%;
  background-size: 67% 87%, 57% 65%, 78% 64%, 60% 80%, 73% 47%;
}

cow:nth-of-type(5n) {
  top: 80%;
  background-position: 21% 37%, 37% 47%, -22% 33%;
}

cow:nth-of-type(7n-4) {
  top: 70%;
  background-size: 67% 77%, 67% 65%, 78% 64%, 73% 47%;
}

cow:nth-of-type(9n-4) {
  top: 30%;
}

cow:nth-of-type(11n-7) {
  top: 40%;
}

cow:nth-of-type(3n-1) {
  left: 50%;
  background-size: 67% 43%, 76% 55%, 87% 54%, 73% 67%;
}

cow:nth-of-type(5n-4) {
  left: 40%;
  background-position: -22% 33%, 21% 37%, 37% 47%, 17% 13%;
}

cow:nth-of-type(9n-7) {
  left: 70%;
  background-size: 76% 55%, 87% 54%, 67% 43%, 73% 67%;
}

cow:nth-of-type(11n-8) {
  left: 10%;
}

cow:nth-of-type(13n-8) {
  left: 30%;
}

cow:nth-of-type(3n+1) {
  animation-name: transform2;
}

cow:nth-of-type(3n+2) {
  animation-name: transform3;
}

cow:nth-of-type(5n) {
  animation-delay: -13s;
}

cow:nth-of-type(5n+1) {
  animation-delay: -4s;
}

cow:nth-of-type(5n+2) {
  animation-delay: -6s;
}

cow:nth-of-type(5n+3) {
  animation-delay: -8s;
}

cow:nth-of-type(5n+4) {
  animation-delay: -10s;
}

cow:nth-of-type(7n) {
  animation-duration: 8s;
}

cow:nth-of-type(7n+1) {
  animation-duration: 7s;
}

cow:nth-of-type(7n+2) {
  animation-duration: 6s;
}

cow:nth-of-type(7n+3) {
  animation-duration: 5s;
}

cow:nth-of-type(7n+4) {
  animation-duration: 6.3s;
}

cow:nth-of-type(7n+5) {
  animation-duration: 5.5s;
}

cow:nth-of-type(7n+6) {
  animation-duration: 7.3s;
}

@keyframes transform1 {
  20% {
    transform: rotate(10deg) scale(0.9) translate(350%, 20%);
  }
  40% {
    transform: rotate(-10deg) translate(-30%, 500%);
  }
  60% {
    transform: rotate(10deg) scale(0.7) translate(200%, -180%);
  }
}

@keyframes transform2 {
  20% {
    transform: rotate(10deg) scale(0.9) translate(50%, 200%);
  }
  40% {
    transform: rotate(-15deg) translate(-200%, -350%);
  }
  60% {
    transform: rotate(7deg) translate(170%, 280%) scale(1.2);
  }
}

@keyframes transform3 {
  20% {
    transform: rotate(10deg) scale(0.7) translate(-130%, 50%);
  }
  40% {
    transform: rotate(-10deg) translate(250%, 200%);
  }
  60% {
    transform: rotate(10deg) scale(1.1) translate(70%, -180%);
  }

}

@keyframes headbob {
  30% {
    transform: rotate(-10deg)
  }
  70% {
    transform: rotate(10deg)
  }
}
</style>
